import React, { useEffect } from "react"
import { Helmet } from 'react-helmet'
import Typed from 'typed.js'
import Layout from '../components/layout'
import Lottie from 'react-lottie-player/dist/LottiePlayerLight'
import lottieJson from '../data/d-lottie.json'

const Home = ({ location }) => {
  const typedEl1 = React.useRef(null)
  const typedEl2 = React.useRef(null)

  const typed1 = React.useRef(null)
  const typed2 = React.useRef(null)

  useEffect(() => {
    const options = {
      typeSpeed: 40,
      backSpeed: 0,
      cursorChar: '',
      loop: false
    }

    typed1.current = new Typed(typedEl1.current, {...options, strings: ['whoami']})
    
    setTimeout(() => {
      typed2.current = new Typed(typedEl2.current, {...options, strings: ['`→`^1000\n`Full-stack Web Developer`^1000,\n`Technical Writer`^1000,\n`YouTube Content Creator`^1000,\n`CTO`'], })
    } , 1200)

    return () => {
      typed1.current.destroy()
      typed2.current.destroy()
    }
  })

  return (
    <Layout location={location}>
      <Helmet title={`Threefields Media`} />
      <div className="flex-none items-center justify-center h-max">
        <Lottie
          loop
          animationData={lottieJson}
          speed={1.5}
          play
          style={{ width: 248, height: 248, margin: '0 auto' }}
        />
        <div className="coding inverse-toggle px-5 shadow-lg text-gray-100 text-sm font-mono subpixel-antialiased bg-gray-800 pb-6 pt-4 rounded-lg leading-normal overflow-hidden">
          <div className="top mb-2 flex">
              <div className="h-3 w-3 bg-red-500 rounded-full"></div>
              <div className="ml-2 h-3 w-3 bg-orange-300 rounded-full"></div>
              <div className="ml-2 h-3 w-3 bg-green-500 rounded-full"></div>
          </div>
          <div className="mt-4">
            <span className="text-green-400">stefanos:~$</span>
            <p className="inline typing items-center pl-2" ref={typedEl1} />
            <p className="typing items-center pl-2" ref={typedEl2} />
          </div>
        </div>
      </div>
      {/* reminder: add something to the console like "Wouldn't be a proper dev blog without some message appearing on the console, would it?" */}
    </Layout>
  )
}

export default Home
